import React, { useEffect, useState } from "react";
import Arrow from "../img/arrow.svg"
import "../scss/modail.scss"
import { ButtonCost } from "./button";

let urlPAy = document.location.search
let params = new URLSearchParams(urlPAy)
let metaInfo = {merchantPointId:"1",merchantId:"8", posterId:"1", desc:"1"}

for(let pair of params.entries()) {
    if(pair[0]){
        metaInfo[pair[0]] = pair[1];
    }
}
// console.log(metaInfo)


export function ModailCost(){
    const [act, stateAct] = useState(false)
    const [meta, stateMeta] = useState({merchantPointId:"1",merchantId:"4", posterId:"1", desc:"1"})
    const [cost, stateCost] = useState('')

    useEffect(()=>{
        stateMeta(metaInfo)

        const costButton = Array.prototype.slice.call(document.querySelectorAll(".cost-button"))
        const displayCurrency = document.querySelector("#imputCost")        
        costButton.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                stateCost(el.target.id)
                document.location.href = `https://www.ipay.ua/ua/charger?bill_id=3414&merchantPointId=${meta["merchantPointId"]}&merchantId=${meta["merchantId"]}&posterId=${meta["posterId"]}&invoice=${el.target.id}&desc=${meta["desc"]}`
                
            })
        })

        console.log(costButton)
        
        displayCurrency.addEventListener("keyup",()=>{
            stateCost(displayCurrency.value)
            // console.log(cost)
        })

        //localhost:3000/?bill_id=3414&merchantPointId=5&merchantId=3&posterId=3&invoice=3&desc=${meta[%22desc%22]

        document.querySelector("#next").addEventListener("click",()=>{
            // console.log(meta)
            stateCost(displayCurrency.value)
            
            // console.log(cost)
            if(cost >= 15 && cost <=100000){
                document.location.href = `https://www.ipay.ua/ua/charger?bill_id=3414&merchantPointId=${meta["merchantPointId"]}&merchantId=${meta["merchantId"]}&posterId=${meta["posterId"]}&invoice=${cost}&desc=${meta["desc"]}`
                displayCurrency.value = cost
                displayCurrency.classList.remove("error")
               
            }else if(cost <= 14 || cost === 0){
                displayCurrency.value = ""
                displayCurrency.placeholder = "мінімальна сума 15грн"
                displayCurrency.classList.add("error")
            }else{
                displayCurrency.value = ""
                displayCurrency.placeholder = "вкажіть суму"
                displayCurrency.classList.add("error")
            }
        })
    })
    return(
        
        <div className={act? "cost cost__act":"cost"}>
            <div className="cost_title">
                <span>Українці - нація героїв</span>
            </div>
            <div className={act? "cost_button cost_button__act":"cost_button cost_button__act"}>
                <ButtonCost id="500"></ButtonCost>
                <ButtonCost id="100"></ButtonCost>
                <ButtonCost id="35"></ButtonCost>
                <input type="number" id="imputCost" placeholder="Оберіть, або введіть свою суму"/>
                <button id="next">ДОПОМОГТИ</button>
            </div>
            {/* <div className="cost_less" onClick={()=>{stateAct(!act)}}>
                <img src={Arrow} alt="" className={act? "arrow arrow__act":"arrow"}/>
                <span>Load less</span>
            </div> */}
        </div>
    )
}
