import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
// import Girl from "../img/girl.png"
import Logo from "../img/logo.png"
import Kh from "../img/girl.png"





export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            <div className="content_midle">
                
                {
                    iW>850? <img className="img-screen" src={Kh} alt="img" />:""
                }
                <div className="case">
                    <span>ДОПОМОЖІТЬ УКРАЇНЦЯМ <br/> ЗАРАЗ</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Kh} alt="img" />
                    }
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            <div className="dop-info">
                <span className="dop-info_title"></span><br />
                <span className="dop-info_sub-title">Зібрані кошти будуть направлені на допомогу постраждалим українським сім'ям від агресії російської федерації. </span><br />
                <span className="dop-info_info">Як працює програма?</span>
                <ul className="dop-info_list">
                    <li>Ми залучаємо кошти знаючи,  які потреби мають волонтери, фонди та ЗСУ. Приймаємо заявки через кабінет партнерів фондів.</li>
                    <li>Ми закуповуємо гуманітарну допомогу на зібрані кошти в українських торгових мережах.  Таким чином  разом з вами підтримуємо українську економіку.</li>
                    <li>Ми працюємо прозоро та відкрито з перевіреними фондами і волонтерами, та зберігаємо звіти по кожній розвантаженню товарів.</li>
                    <li>Волонтери отримують гуманітарну допомогу зі складів наших партнерів (торговельні мережі, виробники продукції).</li>
                </ul>
            </div>
            <span className="footer_title"></span>
            <span className="footer_sub-title">©2022 БО "БФ "УКРАЇНСЬКА ОСВІТНЯ ФУНДАЦІЯ". All rights reserved.</span>
        </footer>
    )
}